export const routes = {
  START_PAGE: '/',
  ALL: '/*',
  LOGIN: '/login',
  SIGN_UP: '/signup',
  SEARCH_PAGE: '/search',
  VERIFY_EMAIL: '/verify-email',
  PRO: '/pro',
  CLIENT: '/client',
  USER_TYPE: '/:userType',
  PASSWORD: '/password',
  RESET: '/reset',
  UPDATE: '/update',
  CONNECT: '/connect',
  FINANCES: '/finances',
  SOCIAL_LOGIN: '/social-login',
  ACCOUNT: '/account',
  INVOICES: '/invoices',
  DASHBOARD: '/dashboard',
  CALENDAR: '/calendar',
  ACCOUNT_TAB: '/:accountTab',
  ACCOUNT_SUB_TAB: '/:accountSubTab',
  PROFILE: '/profile',
  SETTINGS: '/settings',
  EVENTS: '/events',
  ACTIVITIES: '/activities',
  CONNECTIONS: '/connections',
  CONNECTION: '/connection',
  CREATE: '/create',
  WELCOME: '/welcome',
  COMPLETE: '/complete',
  GENERAL: '/general',
  STEP: '/:step',
  MEETING: '/meeting/:meetingId',
  MEETING_LINK: '/meeting',
  SCHEDULE: '/schedule',
  PRO_UUID: '/:pro_uuid',
  EVENT_ACTION: '/:action',
  ACTION_PARAM: '/:action',
  EVENT_UUID: '/:eventUuid',
  INVOICE_UUID: '/:invoiceId',
  BOOKING_POLICY: '/booking-policy',
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_AND_CONDITIONS: '/user-agreement',
  CASES: '/cases',
  USER_INVITE: '/invite-registration',
  XIRA_FOR_LAWYERS: '/xira-for-lawyers',
  OLD_BROWSER_WARNING: '/old-browser-warning',
  PAY_NOW: '/pay-now',
  ABOUT_XIRA: '/about-xira',
  ZENDESK: '/zendesk',
  ZENDESK_LOGIN: '/zendesk-login',
  FOOTER_TAB: '/:footerTab',
  CONTACT_US: '/contact-us',
  TUTORIALS: '/tutorials',
  MESSAGING: '/messaging',
  VAULT: '/vault',
  VIEW: '/view',
  WALLET: '/my-wallet',
  RATING: '/rating/:ratingID',
  PRO_EVENT_RESCHEDULE: '/:event_uuid/reschedule',
  PRO_EVENT_CANCEL: '/:event_uuid/cancel',
  PREVIEW: '/preview',
  PREVIEW_ONLY: '/preview-search',
  PREVIEW_AI_FORM: '/preview-ai-form',
  SIGN_UP_ONLY: '/sign-up',
  SIGN_IN: '/sign-in',
  UPLOADED: '/uploaded',
  AI_CHAT: '/ai-chat',

  getAiChatPath: function() {
    return `${this.AI_CHAT}`
  },
  getMeetingPagePath: function() {
    return `${this.MEETING}`
  },
  getSearchPagePath: function() {
    return `${this.SEARCH_PAGE}`
  },
  getVerifyEmailPath: function() {
    return `${this.VERIFY_EMAIL}`
  },
  getSocialLoginPath: function() {
    return `${this.SOCIAL_LOGIN}`
  },
  getClientPath: function() {
    return `${this.CLIENT}`
  },
  getProPath: function() {
    return `${this.PRO}`
  },
  getStartPagePath: function() {
    return `${this.START_PAGE}`
  },
  getUserLoginPath: function() {
    return `${this.LOGIN}`
  },
  getUserSignupPath: function() {
    return `${this.SIGN_UP}`
  },
  getUserAllPasswordPath: function() {
    return `${this.PASSWORD}${this.ALL}`
  },
  getUserPasswordResetPath: function() {
    return `${this.PASSWORD}${this.RESET}`
  },
  getUserPasswordUpdatePath: function() {
    return `${this.PASSWORD}${this.UPDATE}`
  },
  getUserPasswordResetUnauthorizedPath: function() {
    return `${this.PASSWORD}${this.RESET}/unauthorized`
  },
  getTermsAndConditionsPath: function() {
    return `${this.TERMS_AND_CONDITIONS}`
  },
  getPrivacyPolicyPath: function() {
    return `${this.PRIVACY_POLICY}`
  },
  getProProfileCreatePath: function() {
    return `${this.PRO}${this.PROFILE}${this.CREATE}`
  },
  getProWelcomePath: function() {
    return `${this.PRO}${this.WELCOME}`
  },
  getProCompletePath: function() {
    return `${this.PRO}${this.COMPLETE}`
  },
  getProAccountPath: function() {
    return `${this.PRO}${this.ACCOUNT}`
  },
  getClientAccountPath: function() {
    return `${this.CLIENT}${this.ACCOUNT}`
  },
  getClientReviewPath: function() {
    return `${this.CLIENT}${this.RATING}`
  },
  getProAccountTabPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.ACCOUNT_TAB}${this.ACCOUNT_SUB_TAB}?`
  },
  getProAccountCalendarConnectPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.CALENDAR}${this.CONNECT}`
  },
  getProAccountDashboardPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.EVENTS}`
  },
  getProAccountEventsPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.EVENTS}`
  },
  getProAccountActivitiesPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.ACTIVITIES}`
  },
  getProAccountCasesPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.CASES}`
  },
  getProAccountCasesCreateLinkPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.CASES}${this.CREATE}`
  },
  getProAccountProfilePath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.PROFILE}`
  },
  getProAccountFinancesPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.FINANCES}`
  },
  getProAccountMessagingPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.MESSAGING}`
  },
  getProAccountVaultPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.VAULT}`
  },
  getProAccountTutorialsPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.TUTORIALS}/:videoId?`
  },
  getProAccountSettingsPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.SETTINGS}`
  },
  getProAccountProfileTabPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.PROFILE}${this.ACCOUNT_SUB_TAB}`
  },
  getProAccountFinancesTabPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.FINANCES}${this.ACCOUNT_SUB_TAB}`
  },
  getProAccountSettingsTabPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.SETTINGS}${this.ACCOUNT_SUB_TAB}`
  },
  getProAccountProfileGeneralPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.PROFILE}${this.GENERAL}`
  },
  getProAccountFinancesGeneralPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.FINANCES}${this.INVOICES}`
  },
  getProAccountFinancesInvoicesViewPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.FINANCES}${this.INVOICES}${this.VIEW}`
  },
  getProAccountSettingsGeneralPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.SETTINGS}${this.CONNECTIONS}`
  },
  getProAccountCalendarPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.CALENDAR}`
  },
  getProAccountCalendarTabPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.CALENDAR}${this.ACCOUNT_SUB_TAB}`
  },
  getProAccountConnectionsPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.CONNECTIONS}`
  },
  getProAccountPredefinedConnectionsPath: function(clientId) {
    return `${this.PRO}${this.ACCOUNT}${this.CONNECTIONS}?${this.CONNECTION}=${clientId}`
  },
  getProAccountCalendarConnectionsPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.CALENDAR}${this.CONNECTIONS}`
  },
  getProProfileCreateStepPath: function() {
    return `${this.PRO}${this.PROFILE}${this.CREATE}${this.STEP}`
  },
  getProProfileCreateStepPropsPath: function(step) {
    return `${this.PRO}${this.PROFILE}${this.CREATE}/${step}`
  },
  getClientAccountDashboardPath: function() {
    return `${this.CLIENT}${this.ACCOUNT}${this.EVENTS}`
  },
  getClientAccountEventActionPath: function() {
    return `${this.CLIENT}${this.ACCOUNT}${this.EVENTS}${this.EVENT_UUID}${this.EVENT_ACTION}`
  },
  getClientAccountInvoiceActionPath: function() {
    return `${this.CLIENT}${this.ACCOUNT}${this.INVOICES}${this.ACTION_PARAM}${this.INVOICE_UUID}`
  },
  getClientAccountInvoiceDetailsViewPath: function() {
    return `${this.CLIENT}${this.ACCOUNT}${this.INVOICES}${this.VIEW}`
  },
  getClientAccountInvoicesPath: function() {
    return `${this.CLIENT}${this.ACCOUNT}${this.INVOICES}`
  },
  getClientAccountTabPath: function() {
    return `${this.CLIENT}${this.ACCOUNT}${this.ACCOUNT_TAB}`
  },
  getClientWelcomePath: function() {
    return `${this.CLIENT}${this.WELCOME}`
  },
  getClientMeetingSchedulePath: function() {
    return `${this.CLIENT}${this.SCHEDULE}${this.PRO_UUID}`
  },
  getClientInvoicePaymentPath: function() {
    return `${this.CLIENT}${this.PAY_NOW}${this.INVOICE_UUID}`
  },
  getClientInvoicePaymentLinkPath: function() {
    return `${this.CLIENT}${this.PAY_NOW}`
  },
  getBookingPolicyPath: function() {
    return `${this.CLIENT}${this.BOOKING_POLICY}`
  },
  getSearchProProfilePath: function() {
    return `${this.SEARCH_PAGE}${this.PRO_UUID}`
  },
  getSearchProExcelProfilePath: function() {
    return `${this.SEARCH_PAGE}${this.PRO_UUID}${this.UPLOADED}`
  },
  getClientInvitePath: function() {
    return `${this.USER_INVITE}`
  },
  getXiraForLawyersPath: function() {
    return `${this.XIRA_FOR_LAWYERS}`
  },
  getOldBrowserWarningPath: function() {
    return `${this.OLD_BROWSER_WARNING}`
  },
  getAboutXiraPath: function() {
    return `${this.ABOUT_XIRA}`
  },
  getZendeskPath: function() {
    return `${this.ZENDESK}`
  },
  getZendeskLoginPath: function() {
    return `${this.ZENDESK_LOGIN}`
  },
  getFooterLeftMenuPath: function() {
    return `${this.FOOTER_TAB}`
  },
  getContactUsPath: function() {
    return `${this.CONTACT_US}`
  },
  getProRescheduleEventModalPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.EVENTS}${this.PRO_EVENT_RESCHEDULE}`
  },
  getProCancelEventModalPath: function() {
    return `${this.PRO}${this.ACCOUNT}${this.EVENTS}${this.PRO_EVENT_CANCEL}`
  },
  getPreviewPath: function() {
    return `${this.PREVIEW}`
  },
  getPreviewSearchOnlyPath: function() {
    return `${this.PREVIEW_ONLY}`
  },
  getPreviewAiFormPath: function() {
    return `${this.PREVIEW_AI_FORM}`
  },
  getSignUpPath: function() {
    return `${this.SIGN_UP_ONLY}`
  },
  getSignInPath: function() {
    return `${this.SIGN_IN}`
  },
}
